
import React from 'react'
import { Link } from 'gatsby'
import Layout from '../components/layout'
const IndexPage = () => (
  <Layout>
    <h1>Support for Global and Premium Brands</h1>
    <p>
      BrandMedia is pioneering 3rd generation Digital Media technologies to
      support Global and Premium Brands - enabling an ecosystem of innovative
      solutions to deliver Customer Success
    </p>
    <h2>Executives</h2>
    <p>
      <b>Anoop Swaminath</b> - CEO
    </p>
    <p>
      <a href="mailto:anoop@brandmedia.org">anoop@brandmedia.org</a>
    </p>
    <br />
    <p>
      <b>Ed Wieczorek</b> - Director of Engineering
    </p>
    <p>
      <a href="mailto:ed@brandmedia.org">ed@brandmedia.org</a>
    </p>
    <br />
    <p>
      Corporate Contact: <a href="tel:4083486497">(408) 348-6497</a>
    </p>
    <div className="box">
      <h4>BrandMedia Corporate Office</h4>
      <p>800 W. El Camino Rd.</p>
      <p>Mountain View, CA 94040</p>
    </div>
    <br />
    <br />
    <p className="copy">&copy; 2018-2020 - BrandMedia Techonlogies</p>
  </Layout>
)
export default IndexPage
